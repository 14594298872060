import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Progress,
  Spinner,
  Container,
  Input,
  Toast,
  ToastBody,
  ButtonDropdown,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
} from "reactstrap";

import classnames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import Loader from "src/components/Common/Loader";
import { withTranslation } from "react-i18next";
import {
  googleAuth,
  googleDisconnect,
  microsoftAuth,
  microsoftDisconnect,
  wppConnect,
} from "src/services/googleAuth";

import { useMutation, useQuery } from "@tanstack/react-query";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import parsePhoneNumberFromString, { AsYouType } from "libphonenumber-js";
import { AnimatePresence, motion } from "framer-motion";

import Microsoft from "../../assets/images/icons/logo-microsoft.svg";
import Google from "../../assets/images/icons/logo-google-g-icon.svg";

type WizardState = {
  activeTabProgress: number;
  progressValue: number;
};

const StepsConnect = (props: any) => {
  const token: string | null = localStorage.getItem("@aime:token");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMicro, setIsLoadingMicro] = useState(false);
  const [btnActive, setBtnActive] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [state, setState] = useState<WizardState>({
    activeTabProgress: 1,
    progressValue: 50,
  });
  const [wpp, setWpp] = useState<string>("");
  const [toast, setToast] = useState(false);
  const [msg, setMsg] = useState("");
  const whatsappNumber: string | null = localStorage.getItem("cell_phone");
  const googleEmail: string | null = localStorage.getItem("email");
  const [disconnectDropGoogle, setdisconnectDropGoogle] = useState(false);
  const [modal_center, setmodal_center_google_disconnect] = useState(false);
  const [modal_center_wpp_disconnect, setmodal_center_wpp_disconnect] =
    useState(false);
  const [modal_center_wpp_alt, setmodal_center_wpp_alt] = useState(false);
  const whatsappLink: string | null = localStorage.getItem("login_whatsapp");
  const [authWindow, setAuthWindow] = useState<any>(null);
  const hasGoogle = localStorage.getItem("has_google");
  const hasMicrosoft = localStorage.getItem("has_microsoft");

  const responseMutation = useMutation({
    mutationKey: ["URL_GOOGLE"],
    mutationFn: googleAuth,
    onSuccess: (data) => {
      if (data.authorization_url && authWindow) {
        authWindow.location.href = data.authorization_url;
      }
      setIsLoading(false);
    },
    onError: (err: any) => {
      setIsLoading(false);
      console.log("erro");
    },
  });

  const microsoftMutation = useMutation({
    mutationKey: ["URL_GOOGLE"],
    mutationFn: microsoftAuth,
    onSuccess: (data) => {
      if (data.authorization_url && authWindow) {
        authWindow.location.href = data.authorization_url;
      }
      setIsLoadingMicro(false);
    },
    onError: (err: any) => {
      setIsLoadingMicro(false);
      console.log("erro");
    },
  });

  const handleAddGoogle = () => {
    const newAuthWindow: any = window.open(
      "",
      "_blank",
      "width=500,height=500"
    );
    setAuthWindow(newAuthWindow);

    setIsLoading(true);
    responseMutation.mutate(token);
  };

  const handleAddMicrosoft = () => {
    const newAuthWindow: any = window.open(
      "",
      "_blank",
      "width=500,height=500"
    );
    setAuthWindow(newAuthWindow);

    setIsLoadingMicro(true);
    microsoftMutation.mutate(token);
  };

  const toggleTabProgress = (tab: number) => {
    if (state.activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 3) {
        setState((prevState) => ({
          ...prevState,
          activeTabProgress: tab,
        }));
      }
      if (tab === 1) {
        setState((prevState) => ({
          ...prevState,
          progressValue: 25,
        }));
      }
      if (tab === 2) {
        setState((prevState) => ({
          ...prevState,
          progressValue: 50,
        }));
      }
      if (tab === 3) {
        setState((prevState) => ({
          ...prevState,
          progressValue: 100,
        }));
      }
    }
  };

  const checkGoogleStatus = () => {
    const hasGoogle = localStorage.getItem("has_google");
    const hasMicrosoft = localStorage.getItem("has_microsoft");

    if (hasGoogle === "yes" || hasMicrosoft === "yes") {
      setBtnActive(false);
    } else {
      setBtnActive(true);
    }
  };

  useEffect(() => {
    checkGoogleStatus();

    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === "has_google") {
        checkGoogleStatus();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    checkGoogleStatus();

    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === "has_microsoft") {
        checkGoogleStatus();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const checkLearningStatus = () => {
    const hasLearned = localStorage.getItem("has_learned");

    if (complete === true) {
      setBtnActive(false);
    } else {
      setBtnActive(true);
    }
  };

  useEffect(() => {
    checkLearningStatus();

    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === "has_learned") {
        checkLearningStatus();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (state.activeTabProgress === 1) {
      checkGoogleStatus();
    } else if (state.activeTabProgress === 3) {
      checkLearningStatus();
    }
  }, [state.activeTabProgress]);

  const responseDisconnect = useMutation({
    mutationKey: ["URL_GOOGLE"],
    mutationFn: googleDisconnect,
    onSuccess: (data) => {
      setIsLoading(false);
      setmodal_center_google_disconnect(false);
      localStorage.setItem("has_google", "no");
      checkGoogleStatus();
    },
    onError: (err: any) => {
      console.log("erro");
      setIsLoading(false);
    },
  });

  const responseDisconnectMicrosoft = useMutation({
    mutationKey: ["URL_MICROSOFT"],
    mutationFn: microsoftDisconnect,
    onSuccess: (data) => {
      setIsLoading(false);
      setmodal_center_google_disconnect(false);
      localStorage.setItem("has_microsoft", "no");
      checkGoogleStatus();
    },
    onError: (err: any) => {
      console.log("erro");
      setIsLoading(false);
    },
  });

  const responseWpp = useMutation({
    mutationKey: ["WHATSAPP"],
    mutationFn: wppConnect,
    onSuccess: (data) => {
      if (data.status === "00") {
        localStorage.setItem("cell_phone", wpp);
        setmodal_center_wpp_alt(false);
        setmodal_center_wpp_disconnect(false);
      } else {
        const messagem = data.response.data.detail[0].msg
          ? data.response.data.detail[0].msg
          : props.t("Não foi possível conectar, tente novamente.");
        setMsg(messagem);
        setIsLoading(false);
        setToast(true);
      }
      setIsLoading(false);
    },
    onError: (err: any) => {
      if (err.response) {
        const messagem = err.response.data.detail[0].msg
          ? err.response.data.detail[0].msg
          : props.t("Não foi possível conectar, tente novamente.");
        setMsg(messagem);
        setIsLoading(false);
        setToast(true);
      } else {
        const messagem = props.t("Não foi possível conectar, tente novamente.");
        setMsg(messagem);
        setIsLoading(false);
        setToast(true);
      }
    },
  });

  const handleDisconnect = () => {
    setIsLoading(true);
    responseDisconnect.mutate();
  };

  const handleDisconnectMicrosoft = () => {
    setIsLoading(true);
    responseDisconnectMicrosoft.mutate();
  };

  const startLoading = () => {
    setLoading(true);
    setInterval(() => {
      localStorage.setItem("has_learned", "yes");
      checkLearningStatus();
      setComplete(true);
    }, 5000);
  };

  const handleClick = () => {
    navigate("/aime");
  };

  const removeFormatting = (number: any) => {
    return number.replace(/[()]/g, "");
  };

  const handleConnectWpp = () => {
    const unformattedNumber = removeFormatting(wpp);
    if (unformattedNumber) {
      setIsLoading(true);
      responseWpp.mutate(unformattedNumber);
    }
  };

  const handeInputChange = (e: any) => {
    const inputNumber = e.target.value;
    const asYouType = new AsYouType("BR");
    const formattedNumber = asYouType.input(inputNumber);
    setWpp(formattedNumber);
  };

  const formatNumber = (number: string) => {
    const phoneNumber = parsePhoneNumberFromString(number, "BR");
    return phoneNumber ? phoneNumber.formatNational() : number;
  };

  function tog_center_google_disconnect() {
    setmodal_center_google_disconnect(!modal_center);
  }

  function tog_center_wpp_disconnect() {
    setmodal_center_wpp_disconnect(!modal_center_wpp_disconnect);
  }

  function tog_center_wpp_alt() {
    setmodal_center_wpp_alt(!modal_center_wpp_alt);
  }

  const handleDisconnectWpp = () => {
    setIsLoading(true);
    setWpp("");
    responseWpp.mutate("");
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={props.t("Google")}
          breadcrumbItem={props.t("Google")}
        />
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <div id="progrss-wizard" className="twitter-bs-wizard">
                  <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames("nav-link ", {
                          active: state.activeTabProgress === 1,
                        })}
                      >
                        <div
                          className="step-icon"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          id="SellerDetails"
                        >
                          <i className="mdi mdi-email"></i>
                        </div>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({
                          active: state.activeTabProgress === 2,
                        })}
                      >
                        <div
                          className="step-icon"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          id="CompanyDocument"
                        >
                          <i className="mdi mdi-whatsapp"></i>
                        </div>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({
                          active: state.activeTabProgress === 3,
                        })}
                      >
                        <div
                          className="step-icon"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          id="CompanyDocument"
                        >
                          <i className="mdi mdi-robot"></i>
                        </div>
                      </NavLink>
                    </NavItem>
                  </ul>

                  <div id="bar" className="mt-4">
                    <Progress
                      color="success"
                      striped
                      animated
                      value={state.progressValue}
                    />
                  </div>
                  <TabContent
                    activeTab={state.activeTabProgress}
                    className="twitter-bs-wizard-tab-content"
                  >
                    <TabPane tabId={1}>
                      <div className="mt-4 text-center">
                        {btnActive ? (
                          <>
                            <div className="text-center mb-3">
                              <h5>{props.t("Conecte seu Email")}</h5>
                              <p className="card-title-desc">
                                {props.t(
                                  "Para que o Aime possa agendar seus emails, precisamos que vc faça a conexão"
                                )}
                              </p>
                            </div>
                            <ul className="list-inline d-flex justify-content-center">
                              <li className="list-inline-item">
                                <button
                                  className="d-flex justify-content-center align-items-center bg-white text-secondary border rounded-1 py-2 px-3"
                                  style={{
                                    borderColor: "#ddd",
                                    width: "120px",
                                    height: "40px",
                                  }}
                                  onClick={() => {
                                    handleAddGoogle();
                                  }}
                                  disabled={isLoading}
                                >
                                  {isLoading ? (
                                    <Spinner
                                      size="sm"
                                      className=""
                                      style={{
                                        color: "#0073cf",
                                      }}
                                    />
                                  ) : (
                                    <div className="d-flex gap-2 align-items-center">
                                      <img
                                        width={25}
                                        src={Google}
                                        alt="Logo Google"
                                      />
                                      <span>Google</span>
                                    </div>
                                  )}
                                </button>
                              </li>
                              <li className="list-inline-item">
                                <button
                                  className="d-flex justify-content-center align-items-center bg-white text-secondary border rounded-1 py-2 px-3"
                                  style={{
                                    borderColor: "#ddd",
                                    width: "120px",
                                    height: "40px",
                                  }}
                                  onClick={() => {
                                    handleAddMicrosoft();
                                  }}
                                  disabled={isLoadingMicro}
                                >
                                  {isLoadingMicro ? (
                                    <Spinner
                                      size="sm"
                                      className=""
                                      style={{
                                        color: "#0073cf",
                                      }}
                                    />
                                  ) : (
                                    <div className="d-flex gap-2 align-items-center">
                                      <img
                                        width={25}
                                        src={Microsoft}
                                        alt="Logo Microsoft"
                                      />
                                      <span>Microsoft</span>
                                    </div>
                                  )}
                                </button>
                              </li>
                            </ul>
                          </>
                        ) : (
                          <>
                            {hasMicrosoft === "yes" ? (
                              <>
                                <div className="text-center mb-2">
                                  <h5>{props.t("Microsoft está conectada")}</h5>
                                </div>
                                <ul className="list-inline d-flex justify-content-center align-items-center gap-2">
                                  {whatsappLink && whatsappLink === "sim" && (
                                    <div className="d-flex justify-content-center">
                                      <a
                                        href="https://wa.me/5511910047016"
                                        className="btn btn-success waves-effect waves-light"
                                      >
                                        {props.t("Voltar ao WhatsApp")}
                                      </a>
                                    </div>
                                  )}
                                  <li className="list-inline-item">
                                    <div className="d-flex gap-3">
                                      <div className="d-flex justify-content-center align-items-center">
                                        <div className="btn-group me-1">
                                          <ButtonDropdown
                                            isOpen={disconnectDropGoogle}
                                            toggle={() =>
                                              setdisconnectDropGoogle(
                                                !disconnectDropGoogle
                                              )
                                            }
                                          >
                                            <Button color="primary">
                                              {" "}
                                              {googleEmail}
                                            </Button>
                                            <DropdownToggle
                                              caret
                                              color="info"
                                              className="btn-sm"
                                            >
                                              <i className="mdi mdi-chevron-down" />
                                            </DropdownToggle>
                                            <DropdownMenu>
                                              <DropdownItem
                                                onClick={() =>
                                                  tog_center_google_disconnect()
                                                }
                                              >
                                                {props.t("Desconectar")}
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </ButtonDropdown>
                                        </div>
                                        <Modal
                                          isOpen={modal_center}
                                          toggle={() => {
                                            tog_center_google_disconnect();
                                          }}
                                          centered={true}
                                        >
                                          <div className="modal-header">
                                            <h5 className="modal-title mt-0">
                                              {props.t("Desconectar Microsoft")}
                                            </h5>
                                            <button
                                              type="button"
                                              onClick={() => {
                                                setmodal_center_google_disconnect(
                                                  false
                                                );
                                              }}
                                              className="close"
                                              data-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <div className="modal-body">
                                            <p>
                                              {props.t(
                                                "Ao desconectar sua conta você nao poderá usar o chat do Aime. Você poderá se conectar novamente a qualquer momento."
                                              )}
                                            </p>
                                            <div className="d-flex gap-3">
                                              <button
                                                type="button"
                                                className="btn btn-soft-danger waves-effect waves-light w-50"
                                                onClick={() => {
                                                  setmodal_center_google_disconnect(
                                                    false
                                                  );
                                                }}
                                              >
                                                {props.t("Cancelar")}
                                              </button>
                                              <button
                                                type="button"
                                                className="btn btn-soft-success waves-effect waves-light w-50"
                                                onClick={() =>
                                                  handleDisconnectMicrosoft()
                                                }
                                                disabled={isLoading}
                                              >
                                                {isLoading ? (
                                                  <div className="label-icon">
                                                    <Spinner
                                                      size="sm"
                                                      className=""
                                                      style={{
                                                        color: "#2ab57d",
                                                        marginTop: "3px",
                                                      }}
                                                    />
                                                  </div>
                                                ) : (
                                                  <>{props.t("Desconectar")}</>
                                                )}
                                              </button>
                                            </div>
                                          </div>
                                        </Modal>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </>
                            ) : (
                              hasGoogle === "yes" && (
                                <>
                                  <div className="text-center mb-2">
                                    <h5>{props.t("Google conectado")}</h5>
                                  </div>
                                  <ul className="list-inline d-flex justify-content-center align-items-center gap-2">
                                    {whatsappLink && whatsappLink === "sim" && (
                                      <div className="d-flex justify-content-center">
                                        <a
                                          href="https://wa.me/5511910047016"
                                          className="btn btn-success waves-effect waves-light"
                                        >
                                          {props.t("Voltar ao WhatsApp")}
                                        </a>
                                      </div>
                                    )}
                                    <li className="list-inline-item">
                                      <div className="d-flex gap-3">
                                        <div className="d-flex justify-content-center align-items-center">
                                          <div className="btn-group me-1">
                                            <ButtonDropdown
                                              isOpen={disconnectDropGoogle}
                                              toggle={() =>
                                                setdisconnectDropGoogle(
                                                  !disconnectDropGoogle
                                                )
                                              }
                                            >
                                              <Button color="primary">
                                                {" "}
                                                {googleEmail}
                                              </Button>
                                              <DropdownToggle
                                                caret
                                                color="info"
                                                className="btn-sm"
                                              >
                                                <i className="mdi mdi-chevron-down" />
                                              </DropdownToggle>
                                              <DropdownMenu>
                                                <DropdownItem
                                                  onClick={() =>
                                                    tog_center_google_disconnect()
                                                  }
                                                >
                                                  {props.t("Desconectar")}
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </ButtonDropdown>
                                          </div>
                                          <Modal
                                            isOpen={modal_center}
                                            toggle={() => {
                                              tog_center_google_disconnect();
                                            }}
                                            centered={true}
                                          >
                                            <div className="modal-header">
                                              <h5 className="modal-title mt-0">
                                                {props.t("Desconectar Google")}
                                              </h5>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  setmodal_center_google_disconnect(
                                                    false
                                                  );
                                                }}
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                              ></button>
                                            </div>
                                            <div className="modal-body">
                                              <p>
                                                {props.t(
                                                  "Ao desconectar sua conta você nao poderá usar o chat do Aime. Você poderá se conectar novamente a qualquer momento."
                                                )}
                                              </p>
                                              <div className="d-flex gap-3">
                                                <button
                                                  type="button"
                                                  className="btn btn-soft-danger waves-effect waves-light w-50"
                                                  onClick={() => {
                                                    setmodal_center_google_disconnect(
                                                      false
                                                    );
                                                  }}
                                                >
                                                  {props.t("Cancelar")}
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn btn-soft-success waves-effect waves-light w-50"
                                                  onClick={() =>
                                                    handleDisconnect()
                                                  }
                                                  disabled={isLoading}
                                                >
                                                  {isLoading ? (
                                                    <div className="label-icon">
                                                      <Spinner
                                                        size="sm"
                                                        className=""
                                                        style={{
                                                          color: "#2ab57d",
                                                          marginTop: "3px",
                                                        }}
                                                      />
                                                    </div>
                                                  ) : (
                                                    <>
                                                      {props.t("Desconectar")}
                                                    </>
                                                  )}
                                                </button>
                                              </div>
                                            </div>
                                          </Modal>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </>
                              )
                            )}
                          </>
                        )}
                      </div>
                    </TabPane>
                    <TabPane tabId={2}>
                      {whatsappNumber && whatsappNumber.trim() !== "" ? (
                        <div className="mt-4 text-center">
                          <div className="d-flex justify-content-center flex-column align-items-center text-center mb-4">
                            <h5>{props.t("WhatsApp conectado")}</h5>
                            <div className="d-flex gap-2 d-flex align-items-center mb-3">
                              <i className="bx bxl-whatsapp font-size-20 align-middle me-1 text-success"></i>
                              <span>{formatNumber(whatsappNumber)}</span>
                              <i
                                className="bx bxs-edit font-size-18 text-info"
                                style={{ cursor: "pointer" }}
                                onClick={tog_center_wpp_alt}
                              ></i>
                              <i
                                className="bx bx-trash font-size-18 text-danger"
                                style={{ cursor: "pointer" }}
                                onClick={tog_center_wpp_disconnect}
                              ></i>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="mt-4 text-center">
                          <div className="d-flex justify-content-center flex-column align-items-center text-center mb-4">
                            <h5>
                              {props.t("Conecte-se com whatsapp (Opcional)")}
                            </h5>
                            <div className="d-flex gap-1 d-flex align-items-center mb-3">
                              <i className="bx bxl-whatsapp font-size-24 align-middle me-1 text-success"></i>
                              <Input
                                name="wpp"
                                type="text"
                                placeholder={props.t("(11) 99999-9999")}
                                value={wpp}
                                maxLength={15}
                                onChange={handeInputChange}
                              />
                              <button
                                type="button"
                                style={{ minWidth: "100px" }}
                                className="btn btn-soft-primary waves-effect waves-light w-50"
                                disabled={false}
                                onClick={() => handleConnectWpp()}
                              >
                                {isLoading ? (
                                  <Spinner
                                    size="sm"
                                    className=""
                                    style={{ color: "#FFF", marginTop: "3px" }}
                                  />
                                ) : (
                                  <>{props.t("Connectar")}</>
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </TabPane>
                    <TabPane tabId={3}>
                      <div className="text-center mb-4">
                        {complete ? (
                          <>
                            <div className="text-center mb-4">
                              <h5>{props.t("Aprendizado Concluido!")}</h5>
                              <p className="card-title-desc">
                                {props.t(
                                  "Aime pronto para uso, você ja pode começar a agendar suas reuniões."
                                )}
                              </p>
                            </div>
                            <Link
                              to="/aime"
                              className="btn btn-success waves-effect waves-light"
                            >
                              {props.t("Começar a usar")}
                            </Link>
                          </>
                        ) : (
                          <>
                            <h5>{props.t("Iniciar Aprendizado")}</h5>
                            <p className="card-title-desc">
                              {props.t(
                                "Ao clicar em aprender, o Aime ira encontrar seus padrões de conversas, aprendendo o seu modo de se comunicar"
                              )}
                            </p>
                            <div>
                              {!loading ? (
                                <button
                                  type="button"
                                  className="btn btn-soft-success mt-2 waves-effect waves-light"
                                  onClick={() => {
                                    startLoading();
                                  }}
                                >
                                  {props.t("Começar a aprender")}
                                </button>
                              ) : (
                                <Loader />
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </TabPane>
                  </TabContent>
                  <ul className="pager wizard twitter-bs-wizard-pager-link">
                    <li
                      className={
                        state.activeTabProgress === 1
                          ? "previous disabled"
                          : "previous"
                      }
                    >
                      <Link
                        to="#"
                        className={
                          state.activeTabProgress === 1
                            ? "btn btn-primary disabled"
                            : "btn btn-primary"
                        }
                        onClick={() => {
                          toggleTabProgress(state.activeTabProgress - 1);
                        }}
                      >
                        <i className="bx bx-chevron-left me-1"></i>{" "}
                        {props.t("Voltar")}
                      </Link>
                    </li>

                    <li
                      className={
                        state.activeTabProgress === 3 ? "next disabled" : "next"
                      }
                    >
                      <button
                        disabled={
                          state.activeTabProgress === 1
                            ? btnActive
                            : state.activeTabProgress === 2
                            ? false
                            : !complete
                        }
                        className="btn btn-primary"
                        onClick={() => {
                          toggleTabProgress(state.activeTabProgress + 1);
                          state.activeTabProgress === 3 && handleClick();
                        }}
                      >
                        {props.t("Próximo")}{" "}
                        <i className="bx bx-chevron-right ms-1"></i>
                      </button>
                    </li>
                  </ul>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div>
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="position-fixed top-0 end-0 p-3"
              style={{ zIndex: 9999 }}
            >
              <Toast
                isOpen={toast}
                id="borderedToast1"
                className="toast-border-primary overflow-hidden mt-3"
              >
                <ToastBody className="bg-danger text-white">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 me-2">
                      <i className="ri-user-smile-line align-middle"></i>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mb-0 text-white">{msg}</h6>
                    </div>
                    <button
                      type="button"
                      className="btn-close btn-close-white me-2 m-auto"
                      data-bs-dismiss="toast"
                      aria-label="Close"
                      onClick={() => {
                        setToast(false);
                      }}
                    ></button>
                  </div>
                </ToastBody>
              </Toast>
            </motion.div>
          </AnimatePresence>
        </div>
        <Modal
          isOpen={modal_center_wpp_disconnect}
          toggle={() => {
            tog_center_google_disconnect();
          }}
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">{props.t("Remover Whatsapp")}</h5>
            <button
              type="button"
              onClick={() => {
                setmodal_center_wpp_disconnect(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <p>
              {props.t("Tem certeza que deseja excluir o número de whatsapp?")}
            </p>
            <div className="d-flex gap-3">
              <button
                type="button"
                className="btn btn-soft-danger waves-effect waves-light w-50"
                onClick={() => {
                  setmodal_center_wpp_disconnect(false);
                }}
              >
                {props.t("Cancelar")}
              </button>
              <button
                type="button"
                className="btn btn-soft-success waves-effect waves-light w-50"
                onClick={() => handleDisconnectWpp()}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="label-icon">
                    <Spinner
                      size="sm"
                      className=""
                      style={{
                        color: "#2ab57d",
                        marginTop: "3px",
                      }}
                    />
                  </div>
                ) : (
                  <>{props.t("Excluir")}</>
                )}
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={modal_center_wpp_alt}
          toggle={() => {
            tog_center_wpp_alt();
          }}
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">{props.t("Alterar Whatsapp")}</h5>
            <button
              type="button"
              onClick={() => {
                setmodal_center_wpp_alt(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <Input
              className="mb-3"
              name="wpp"
              type="text"
              placeholder={props.t("(11) 99999-9999")}
              value={wpp}
              maxLength={15}
              onChange={handeInputChange}
            />
            <div className="d-flex gap-3">
              <button
                type="button"
                className="btn btn-soft-danger waves-effect waves-light w-50"
                onClick={() => {
                  setmodal_center_wpp_alt(false);
                }}
              >
                {props.t("Cancelar")}
              </button>
              <button
                type="button"
                className="btn btn-soft-success waves-effect waves-light w-50"
                onClick={() => handleConnectWpp()}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="label-icon">
                    <Spinner
                      size="sm"
                      className=""
                      style={{
                        color: "#2ab57d",
                        marginTop: "3px",
                      }}
                    />
                  </div>
                ) : (
                  <>{props.t("Alterar")}</>
                )}
              </button>
            </div>
          </div>
        </Modal>
      </Container>
    </div>
  );
};

export default withTranslation()(StepsConnect);
