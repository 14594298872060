import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Aime from "../pages/Aime";

//Utility
import Error404 from "src/pages/Utility/Error404";
import Error500 from "src/pages/Utility/Error500";

//Authentication pages
import Login from "src/pages/Authentication/Login";
import Logout from "src/pages/Authentication/Logout";
import Register from "src/pages/Authentication/Register";
import AuthLogout from "../pages/AuthenticationInner/Logout";

import StepsConnect from "src/pages/Steps/StepsConnect";

import GoogleAuthentication from "src/pages/GoogleAuthentication";
import MicrosoftAuthentication from "src/pages/MicrosoftAuthentication";
import LoginWhatsapp from "src/pages/LoginWhatsapp";

//Contacts
import ContactsList from "src/pages/Contacts/ContactList/contacts-list";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const userRoutes: Array<RouteProps> = [

  //aime
  { path: "/aime", component: <Aime /> },


  //Steps
  {path: "/steps", component: <StepsConnect />},

  { path: "/", exact: true, component: <Navigate to="/aime" /> },

   //Contact
   { path: "/contacts-list", component: <ContactsList /> },

];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/register", component: <Register /> },

  //AuthenticationInner pages
  { path: "/page-logout", component: <AuthLogout /> },

  //utility page
  { path: "/pages-404", component: <Error404 /> },
  { path: "/pages-500", component: <Error500 /> },

  { path: "/google/authorization/token", exact: true, component: <GoogleAuthentication /> },
  { path: "/microsoft/authorization/token", exact: true, component: <MicrosoftAuthentication /> },
  { path: "/auth/user_token", exact: true, component: <LoginWhatsapp /> },
];

export { userRoutes, authRoutes };
