import React from 'react';
import { Link } from 'react-router-dom';

const Name = (cell : any) => {
    return (
        <React.Fragment>
          <h5 className="font-size-14 m-0">
            <Link to="#" className="text-dark">
              {cell.value}
            </Link>
          </h5>
        </React.Fragment>
    )
};

const Email = (cell : any) => {
    return cell.value ? cell.value : '';
};

export {
    Name,
    Email,
};
