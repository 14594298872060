import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";

import { Table, Row, Col, Button, Input } from "reactstrap";
import { Filter, DefaultColumnFilter } from "./filters";
import { useTranslation } from "react-i18next";

interface GlobalFilterProps {
  preGlobalFilteredRows: any;
  globalFilter?: any;
  setGlobalFilter: any;
  onSearchInputChange: (searchTerm: string) => void;
}

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  onSearchInputChange,
}: GlobalFilterProps) {
  const [value, setValue] = React.useState(globalFilter || "");
  const { t } = useTranslation();

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onSearchInputChange(value);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [value]);

  return (
    <Col sm={4}>
      <div className="search-box me-2 mb-2 d-inline-block">
        <div className="position-relative">
          <label htmlFor="search-bar-0" className="search-label">
            <input
              onChange={(e) => {
                setValue(e.target.value);
              }}
              id="search-bar-0"
              type="text"
              className="form-control"
              placeholder={t("Pesquisar...") || ""}
              value={value || ""}
            />
          </label>
          <i className="bx bx-search-alt search-icon"></i>
        </div>
      </div>
    </Col>
  );
}

interface TableContainerProps {
  columns: any;
  data: any;
  isGlobalFilter?: any;
  isAddUserList?: any;
  handleUserClick?: any;
  customPageSize: any;
  className?: any;
  gotoPage: (page: number) => void;
  nextPage: () => void;
  previousPage: () => void;
  canNextPage: boolean;
  canPreviousPage: boolean;
  pageCount: number;
  pageIndex: number;
  onSearchInputChange: (searchTerm: string) => void;
}

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isAddUserList,
  handleUserClick,
  customPageSize,
  className,
  gotoPage,
  nextPage,
  previousPage,
  canNextPage,
  canPreviousPage,
  pageCount,
  pageIndex,
  onSearchInputChange,
}: TableContainerProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: 0, pageSize: customPageSize },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const { t } = useTranslation();

  const generateSortingIndicator = (column: any) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  const onChangeInSelect = (event: any) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event: any) => {
    const page = event.target.value ? Number(event.target.value) : 1;
    gotoPage(page);
  };

  return (
    <Fragment>
      <Row className="mb-2">
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            onSearchInputChange={onSearchInputChange}
          />
        )}
        {isAddUserList && (
          <Col sm="8">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                {t("Criar contato")}
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table align-middle datatable dt-responsive table-check nowrap dataTable no-footer">
            {headerGroups.map((headerGroup: any) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={column.id}
                    style={{
                      width: column.Header === "Ação" ? "90px" : "auto",
                    }}
                  >
                    <div
                      className="pb-0 pe-0 ps-0"
                      {...column.getSortByToggleProps()}
                    >
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row: any) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map((cell: any) => {
                      return (
                        <td
                          valign="middle"
                          key={cell.id}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => gotoPage(1)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          {t("Página")}{" "}
          <strong>
            {pageIndex + 1} {t("de")} {pageCount}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={pageCount}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={() => gotoPage(pageCount)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
