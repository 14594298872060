import api from "./api";

type ContactListProps = {
  contacts: {
    id: number;
    uuid: string;
    user_id: number;
    name: string;
    email: string;
    cell_phone: string;
    created_at: string;
  }[];
  current_page: number;
  has_next_page: boolean;
  has_previous_page: boolean;
  total_items: number;
  total_pages: number;
};

type CreateProps = {
  email: string;
  name: string;
};

type UpdateProps = {
  uuid: string;
  email: string;
  name: string;
};

type DeleteProps = {
  uuid: string;
};


export async function contactLists(page: number | null) {
  try {
    const { data } = await api.get<ContactListProps>(
      `/contact/list?page_number=${page}`
    );
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function contactListsSearch(page: number | null, search: string | null) {
  try {
    const { data } = await api.get<ContactListProps>(
      `/contact/search?param=${search}&page_number=${page}`
    );
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function contactCreate({ email, name }: CreateProps) {
  const { data } = await api.post(`/contact/create`, {
    email,
    name,
  });
  return data;
}

export async function contactUpdate({ uuid, email, name }: UpdateProps) {
  const { data } = await api.put(`/contact/update/${uuid}`, {
    email,
    name,
  });
  return data;
}

export async function contactDelete({ uuid }: DeleteProps) {
  const { data } = await api.delete(`/contact/delete/${uuid}`);

  return data;
}
