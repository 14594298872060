import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import TableContainer from "../../../components/Common/TableContainer";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Toast,
  ToastBody,
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

import { Name, Email } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";

import {
  contactCreate,
  contactDelete,
  contactLists,
  contactListsSearch,
  contactUpdate,
} from "src/services/contacts";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from 'react-i18next';

const ContactsList = () => {

  const { t } = useTranslation();
  const [contact, setContact] = useState<any>();
  const [modal, setModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [toast, setToast] = useState(false);
  const [feedBack, setFeedBack] = useState("");
  const [msg, setMsg] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  document.title = t("Lista de contatos | Aime");

  const {
    data: listContacts,
    isSuccess: isSuccessContacts,
    refetch: refetchContacts,
  } = useQuery({
    queryFn: () => contactLists(page),
    queryKey: ["CONTACT_LIST", page],
    enabled: false,
  });

  const {
    data: listContactsSearch,
    isSuccess: isSuccessContactsSearch,
    refetch: refetchContactsSearch,
  } = useQuery({
    queryFn: () => contactListsSearch(page, searchTerm),
    queryKey: ["CONTACT_LIST", page],
    enabled: false,
  });

  useEffect(() => {
    if (searchTerm) {
      refetchContactsSearch();
    } else {
      refetchContacts();
    }
  }, [searchTerm, page]);

  useEffect(() => {
    console.log(searchTerm)
  }, [searchTerm]);

  const data =
    searchTerm &&
    listContactsSearch &&
    listContactsSearch.contacts &&
    listContactsSearch.contacts.length > 0
      ? listContactsSearch
      : listContacts &&
        listContacts.contacts &&
        listContacts.contacts.length > 0
      ? listContacts
      : {
          contacts: [],
          current_page: 1,
          has_next_page: false,
          has_previous_page: false,
          total_items: 0,
          total_pages: 1,
        };

  const createContactMutation = useMutation({
    mutationKey: ["CREATE_CONTACT"],
    mutationFn: contactCreate,
    onSuccess: (data) => {
      if (data.status == "00") {
        setFeedBack("bg-success");
        setMsg(t("Contato criado com sucesso!") || "");
        setToast(true);
      } else if (data.status == "01") {
        setFeedBack("bg-danger");
        setMsg(data.message);
        setToast(true);
      } else {
        setFeedBack("bg-danger");
        setMsg(t("Não foi possível criar o contato, tente novamente.") || "");
        setToast(true);
      }

      refetchContacts();
      setTimeout(() => {
        setToast(false);
      }, 2500);
    },
    onError: (err: any) => {
      setFeedBack("bg-danger");
      setMsg(t("Não foi possível criar o contato, tente novamente.") || "");
      setToast(true);

      setTimeout(() => {
        setToast(false);
      }, 2500);
    },
  });

  const updateContactMutation = useMutation({
    mutationKey: ["UPDATE_CONTACT"],
    mutationFn: contactUpdate,
    onSuccess: (data) => {
      if (data.status == "00") {
        setFeedBack("bg-success");
        setMsg(t("Contato editado com sucesso!") || "");
        setToast(true);
      } else if (data.status == "01") {
        setFeedBack("bg-danger");
        setMsg(data.message);
        setToast(true);
      } else {
        setFeedBack("bg-danger");
        setMsg(t("Não foi possível editar o contato, tente novamente.") || "");
        setToast(true);
      }
      refetchContacts();
      setTimeout(() => {
        setToast(false);
      }, 2500);
    },
    onError: (err: any) => {
      setFeedBack("bg-danger");
      setMsg(t("Não foi possível editar o contato, tente novamente.") || "");
      setToast(true);

      setTimeout(() => {
        setToast(false);
      }, 2500);
    },
  });

  const deleteContactMutation = useMutation({
    mutationKey: ["DELETE_CONTACT"],
    mutationFn: contactDelete,
    onSuccess: (data) => {
      if (data.status == "00") {
        setFeedBack("bg-success");
        setMsg(t("Contato removido com sucesso!") || "");
        setToast(true);
      } else if (data.status == "01") {
        setFeedBack("bg-danger");
        setMsg(data.message);
        setToast(true);
      } else {
        setFeedBack("bg-danger");
        setMsg(t("Não foi possível remover o contato, tente novamente.") || "");
        setToast(true);
      }
      refetchContacts();
      setTimeout(() => {
        setToast(false);
      }, 2500);
    },
    onError: (err: any) => {
      setFeedBack("bg-danger");
      setMsg(t("Não foi possível remover o contato, tente novamente.") || "");
      setToast(true);

      setTimeout(() => {
        setToast(false);
      }, 2500);
    },
  });

  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: (contact && contact.name) || "",
      email: (contact && contact.email) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Por favor preencher seu Nome") || ""),
      email: Yup.string().required(t("Por favor preencher seu Email") || ""),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        updateContactMutation.mutate({
          uuid: contact.uuid,
          email: values["email"],
          name: values["name"],
        });

        // update user
        validation.resetForm();
        setIsEdit(false);
      } else {
        createContactMutation.mutate({
          email: values["email"],
          name: values["name"],
        });

        // new user
        validation.resetForm();
      }
      toggle();
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: t("Nome"),
        filterable: false,
        accessor: "name",
        Cell: (cellProps: any) => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: t("Email"),
        accessor: "email",
        filterable: false,
        Cell: (cellProps: any) => {
          return <Email {...cellProps} />;
        },
      },
      {
        Header: t("Ação"),
        filterable: false,
        accessor: "action",
        Cell: (cellProps: any) => {
          return (
            <div className="d-flex gap-3 justify-content-center">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    const contactData = cellProps.row.original;
                    handleUserClick(contactData);
                  }}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => {
                    const contactData = cellProps.row.original;
                    onClickDelete(contactData);
                  }}
                ></i>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = (arg: any) => {
    const user = arg;

    setContact({
      uuid: user.uuid,
      name: user.name,
      email: user.email,
    });
    setIsEdit(true);

    toggle();
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (users: any) => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    deleteContactMutation.mutate({ uuid: contact.uuid });
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setContact({});
    setIsEdit(false);
    toggle();
  };

  const nextPage = () => {
    if (data?.has_next_page) {
      setPage((old) => old + 1);
    }
  };

  const previousPage = () => {
    if (data?.has_previous_page) {
      setPage((old) => Math.max(old - 1, 1));
    }
  };

  const handleSearchInputChange = (searchTerm: any) => {
    setSearchTerm(searchTerm);
    setPage(1);
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Contacts" breadcrumbItem={t('Lista de contatos')} />
          {data && data.contacts && (
            <>
              <Row>
                <Col lg="12">
                  <Card style={{ marginBottom: 80 }}>
                    <CardBody>
                      <Row>
                        <Col xl="12">
                          <TableContainer
                            columns={columns}
                            data={data.contacts}
                            isGlobalFilter={true}
                            isAddUserList={true}
                            handleUserClick={handleUserClicks}
                            customPageSize={10}
                            className="custom-header-css"
                            gotoPage={setPage}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            canNextPage={data.has_next_page}
                            canPreviousPage={data.has_previous_page}
                            pageCount={data.total_pages}
                            pageIndex={page - 1}
                            onSearchInputChange={handleSearchInputChange}
                          />
                          <Modal isOpen={modal} toggle={toggle}>
                            <ModalHeader toggle={toggle} tag="h4">
                              {!!isEdit
                                ? t("Editar Contato")
                                : t("Adicionar Contato")}
                            </ModalHeader>
                            <ModalBody>
                              <Form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  validation.handleSubmit();
                                  return false;
                                }}
                              >
                                <Row>
                                  <Col xs={12}>
                                    <div className="mb-3">
                                      <Label className="form-label">{t('Nome')}</Label>
                                      <Input
                                        name="name"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.name || ""}
                                        invalid={
                                          validation.touched.name &&
                                          validation.errors.name
                                            ? true
                                            : false
                                        }
                                      />
                                      {validation.touched.name &&
                                      validation.errors.name ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.name}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                    <div className="mb-3">
                                      <Label className="form-label">
                                        {t('Email')}
                                      </Label>
                                      <Input
                                        name="email"
                                        label="Email"
                                        type="email"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.email || ""}
                                        invalid={
                                          validation.touched.email &&
                                          validation.errors.email
                                            ? true
                                            : false
                                        }
                                      />
                                      {validation.touched.email &&
                                      validation.errors.email ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.email}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="text-end">
                                      <button
                                        type="submit"
                                        className="btn btn-success save-user"
                                      >
                                        {t('Salvar')}
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </Form>
                            </ModalBody>
                          </Modal>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <div
                    className="position-fixed top-0 end-0 p-3"
                    style={{ zIndex: 9999 }}
                  >
                    <Toast
                      isOpen={toast}
                      id="borderedToast1"
                      className="toast-border-primary overflow-hidden mt-3"
                    >
                      <ToastBody className={feedBack}>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 me-2">
                            <i className="ri-user-smile-line align-middle"></i>
                          </div>
                          <div className="flex-grow-1">
                            <h6 className="mb-0 text-white">{msg}</h6>
                          </div>
                          <button
                            type="button"
                            className="btn-close btn-close-white me-2 m-auto"
                            data-bs-dismiss="toast"
                            aria-label="Close"
                            onClick={() => {
                              setToast(false);
                            }}
                          ></button>
                        </div>
                      </ToastBody>
                    </Toast>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ContactsList;
