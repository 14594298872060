import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { whatsappLogin } from "src/services/googleAuth";
import { useAuth } from "src/hooks/signIn";
import { useTranslation } from "react-i18next";


const LoginWhatsapp = () => {

  // TRANSLATE
  const { t } = useTranslation();

  // CAPTURA DA URL
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const user = queryParams.get('user');
  const token = queryParams.get('token');
  const contact = queryParams.get('redirect');
  const { handleAuth } = useAuth();
  const navigate = useNavigate();
  
  const [msg, setMsg] = useState("");

  const loginMutation = useMutation({
    mutationKey: ['CODE_GOOGLE'],
    mutationFn: whatsappLogin,
    onSuccess: (data: any) => {
      handleAuth(data);
      localStorage.setItem('login_whatsapp', 'sim');
      if(contact === 'contacts') {
        navigate('/contacts-list');
      } else {
        navigate('/steps');
      }
      
    },
    onError: (err: any) => {
      setMsg(t('Erro ao tentar logar, tente novamente.') || "")
    },
  }); 

  useEffect(() => {
    const loginPayload = {
      user_uuid: user,
      token: token,
    };
    loginMutation.mutate(loginPayload);
  }, [])

  return (
    <>
      {msg && (
        <>{msg}</>
      )}
    </>
  )
}

export default LoginWhatsapp;
